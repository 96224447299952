import PropTypes from "prop-types";
import { Component } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import h from "h";
import c from "classnames";
import { div } from "tags";

class ErrorContainer extends Component {
  static propTypes = {
    className: PropTypes.string,
    innerClass: PropTypes.string,
    showReady: PropTypes.bool,
    field: PropTypes.shape({
      error: PropTypes.string,
      isReady: PropTypes.bool.isRequired,
    }).isRequired,
    noCheck: PropTypes.bool,
  };

  render() {
    const { showReady, field, children, noCheck = false, innerClass } = this.props;
    const error = field.error;
    const isReady = field.isReady;

    const className = c(
      "error-container",
      this.props.className,
      error && "field-error",
      isReady && showReady !== false && "field-ready",
      noCheck && "no-check"
    );

    const style = {
      position: "relative",
    };

    return div({ className, style }, [
      div({ key: "field", className: innerClass, style: { lineHeight: 0 } }, children),
      h(TransitionGroup,

        error && h(
          CSSTransition,
          {
            classNames: "form-error",
            timeout: {
              enter: 500,
              exit: 500,
            },
          },
          div(".error_msg", { key: "error" }, error)
        ),
      ),
    ]);
  }
}

export default ErrorContainer;
