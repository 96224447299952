import identity from "lodash/identity";
import constant from "lodash/constant";
import mailcheck from "mailcheck";

export default function getEmailSuggestion(email) {
  const result = mailcheck.run({
    email: email,
    suggested: identity,
    empty: constant({ full: null })
  });

  if (/.+\..+/.test(result.domain)) {
    return result.full;
  } else {
    return null;
  }
}
