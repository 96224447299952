import PropTypes from "prop-types";
import { Component } from "react";
import { form as formTag } from "tags";
import h from "h";
import SubmitButton from "./SubmitButton";
import DeleteButton from "./DeleteButton";
import BackButton from "./BackButton";
import isEqual from "lodash/isEqual";

class ManagedForm extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    submit: PropTypes.string,
    onDelete: PropTypes.func
  };

  static propDefaults = {
    submit: "Submit"
  };

  static contextTypes = {
    formContext: PropTypes.func
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!isEqual(this.props.currentValues, newProps.currentValues)) {
      this.props.form.mergeCurrentValues(newProps.currentValues);
    }
  }

  // See explanation in ScrollableModal
  wrapChildren(children) {
    if (this.context.formContext) {
      const submitButton = h(SubmitButton, {
        form: this.props.form,
        text: this.props.submit,
        key: "submit"
      });

      const deleteButton =
        this.props.onDelete &&
        h(
          DeleteButton,
          {
            onClick: this.props.onDelete
          },
          this.props.delete || "Delete"
        );
      const backButton =
        this.props.back &&
        h(
          BackButton,
          {
            onClick: this.props.onBack
          },
          this.props.back
        );

      return this.context.formContext(
        children,
        submitButton,
        deleteButton,
        backButton
      );
    } else {
      return children;
    }
  }

  render() {
    const { form, className, onSubmit, children } = this.props;

    const handleSubmit = e => {
      e.preventDefault();
      form.submit(values => onSubmit(values));
    };

    return formTag(
      { className, method: "POST", onSubmit: handleSubmit, id: form.id },
      this.wrapChildren(children)
    );
  }
}

export default ManagedForm;
