import PropTypes from "prop-types";
import c from "classnames";
import { withState, compose } from "recompose";
import { h } from "h";
import { div } from "tags";
import { fieldType } from "./PropTypes";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";
import Input from "./Input";
import FlatCheckbox from "./FlatCheckbox";
import withMetrics from "cp/withMetrics";
import { PasswordRequirementsToolTip } from "../components/passwordRequirements";

const CreatePasswordField = props => {
  const {
    width,
    label,
    help,
    field,
    show,
    optional = false,
    autoFocus,
    metrics,
    hasFocus,
    setHasFocus
  } = props;

  const className = c(
    "field",
    width ? `grid${width}` : "grid12",
    help && "field-with-help"
  );

  const type = show.checked ? "text" : "password";
  const password = field.value;

  const isMobile = metrics.window.width <= 460;

  const showReq = isMobile || hasFocus;

  const customField = {
    ...field,
    onFocus: () => {
      setHasFocus(true);
      field.onFocus();
    },
    onBlur: e => {
      setHasFocus(false);
      field.onFocus(e);
    }
  };

  return h("div.create-password-field", { className }, [
    showReq && h(PasswordRequirementsToolTip, { password }),
    h(LabelledField, { label, help, optional }, [
      h(ErrorContainer, { field, className: "password-field" }, [
        h(Input, {
          type,
          field: customField,
          className: "passwd-input",
          autoFocus
        }),
        div(".passwd-show", [h(FlatCheckbox, { field: show }, "show")])
      ])
    ])
  ]);
};

CreatePasswordField.propTypes = {
  width: PropTypes.number,
  label: PropTypes.string,
  help: PropTypes.node,
  field: fieldType,
  show: fieldType,
  autoFocus: PropTypes.bool
};

const enhance = compose(withState("hasFocus", "setHasFocus", false), withMetrics);

export default enhance(CreatePasswordField);
