import PropTypes from "prop-types";
import { Component } from "react";
import { label, div } from "tags";
import h from "h";

export default class RadioButton extends Component {
  render() {
    const {
      field,
      style,
      disabled,
      children,
      className,
      value,
      defaultChecked
    } = this.props;
    return label(".radiobutton", { style, className }, [
      h("input", {
        type: "radio",
        name: field.name,
        value,
        disabled,
        onChange: field.onChange,
        defaultChecked
      }),
      div(".radiobutton-label", children)
    ]);
  }
}

RadioButton.propTypes = {
  field: PropTypes.object.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool
};
