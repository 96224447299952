import { Component } from "react";
import h from "h";
import throttle from "raf-throttle";

const measurements = () => ({
  window: { width: window.innerWidth, height: window.innerHeight },
  body: document.body.getBoundingClientRect()
});

const Enhance = Inner =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = measurements();
    }

    componentDidMount() {
      this.eventHandler = throttle(() => this.updateMetrics());
      window.addEventListener("scroll", this.eventHandler);
      window.addEventListener("resize", this.eventHandler);
    }

    componentWillUnmount() {
      window.removeEventListener("scroll", this.eventHandler);
      window.removeEventListener("resize", this.eventHandler);
    }

    updateMetrics() {
      this.setState(measurements());
    }

    render() {
      return h(Inner, { ...this.props, metrics: this.state }, this.props.children);
    }
  };

export default Enhance;
