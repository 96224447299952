import { compose, withState, withHandlers, withProps } from "recompose";

const addValidation = validate => props => {
  const errors = validate(props.model, props);
  return { errors };
};

const handleSubmit = props => callback => {
  props.setFormWasSubmitted(true, () => {
    if (Object.keys(props.errors).length == 0) {
      props.handleSubmit(callback);
    }
  });
};

const enhance = validate =>
  compose(
    withState("formWasSubmitted", "setFormWasSubmitted", false),
    withProps(addValidation(validate)),
    withHandlers({ handleSubmit })
  );

export default validate => {
  if (validate) {
    return WrappedComponent => enhance(validate)(WrappedComponent);
  } else {
    return WrappedComponent => WrappedComponent;
  }
};
