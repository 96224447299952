import { compose, withState, withHandlers, mapProps } from "recompose";

const bindInput = props => name => {
  const validationErrors = (props.errors && props.errors[name]) || [];
  const submitError = props.submitErrors && props.submitErrors[name];
  const fieldErrors = [...validationErrors, submitError].filter(x => !!x);
  const isDirty = props.formWasSubmitted || props.isDirty(name);

  const fieldProps = {
    name,
    value: props.model[name] || "",
    checked: !!props.model[name],
    isReady: isDirty && !fieldErrors[0],
    onChange: e => handleChange(props, name, e),
    onFocus: () => handleFocus(props, name),
    onBlur: e => handleBlur(props, name, e)
  };

  if (isDirty && fieldErrors.length > 0) {
    fieldProps.error = fieldErrors[0];
  }
  return fieldProps;
};

function handleChange(props, name, e) {
  if (isEvent(e)) {
    const { target } = e;
    if (target.type === "checkbox") {
      props.setProperty(name, target.checked);
    } else {
      props.setProperty(name, target.value);
    }
  } else {
    props.setProperty(name, e);
  }
}

function handleFocus(props, name) {
  props.enterField(name);
}

// 1Password on iOS does something weird with mobile Firefox in our
// signin form: the DOM onChange event for the username doesn't fire,
// but we do get focus and blur events.  The sequence of events is:
//
//   * page loads
//     - focus username
//
//   * 1password starts filling in form
//     - blur username (field is empty)
//     - focus username
//     - blur username (field now contains username)
//     - focus password
//     - blur password (field now contains password)
//
// Not getting onChange events means although the data is in the form
// on screen, we aren't holding it in our copy of the data in the form
// wrapper. So the next setState() causes the data that 1Password has
// entered to get overwritten.
//
// The fix is to call handleChange() when we get a blur event, so that
// we can keep our copy of the field data up to date.
function handleBlur(props, name, e) {
  handleChange(props, name, e);
  props.exitField(name);
}

function isEvent(candidate) {
  return !!(candidate && candidate.stopPropagation && candidate.preventDefault);
}

const setFieldNamesAndValues = props => fields => {
  props.setFieldNames(Object.keys(fields), () =>
    props.setFieldNamesAndValues(fields)
  );
};

const removeFieldsAndValues = props => fields => {
  const newKeys = Object.keys(props).filter(k => !fields.includes(k));
  props.setFieldNames(newKeys, () => props.removeFields(fields));
};

// This allows fields to be added by the user. Doing this in formValues.js didn't work because it
// would just add properties to the model and not add fields to the form.
const addFieldNamesAndValues = props => (oldFields, newFields) => {
  const simpleOldFields = toSimpleFields(oldFields);
  const fields = { ...simpleOldFields, ...newFields };
  props.setFieldNames(Object.keys(fields), () => props.mergeCurrentValues(fields));
};

// This allows fields to be deleted by the user. Doing this in formValues.js didn't work because it
// would just remove properties from the model and not remove fields to the form.
const deleteFieldNamesAndValues = props => (oldFields, keys) => {
  const simpleOldFields = toSimpleFields(oldFields);
  const newFields = filterKeys(simpleOldFields, keys);
  props.setFieldNames(Object.keys(newFields), () =>
    props.setCurrentValues(newFields)
  );
};

const toSimpleFields = fields => {
  return Object.keys(fields).reduce(
    (newObj, key) =>
      Object.assign(newObj, { [fields[key].name]: fields[key].value }),
    {}
  );
};

const filterKeys = (fields, keys) => {
  return Object.keys(fields)
    .filter(key => !keys.includes(key))
    .reduce((newObj, key) => Object.assign(newObj, { [key]: fields[key] }), {});
};

function createForm(props, opts) {
  const fieldNames = props.fieldNames || props.formFieldNames;
  const isChanged = fieldNames.some(name => props.isChanged(name));
  const isValid = fieldNames.every(
    name => !props.errors || !props.errors[name] || props.errors[name].length == 0
  );
  const formError =
    props.formError || (props.submitErrors && props.submitErrors["_error"]);

  const {
    // This is what is available to us via props (including
    // outer HOCs and our own handlers)
    setProperty,
    setCurrentValues,
    removeFields,
    setFieldNamesAndValues,
    removeFieldsAndValues,
    notifySubmitErrors,
    bindInput,
    model,
    errors,
    submitErrors,
    addFieldNamesAndValues,
    deleteFieldNamesAndValues,
    mergeCurrentValues,
    ...rest
  } = props;

  return {
    // This is what we make available to the outside
    setProperty,
    setCurrentValues,
    removeFields,
    setFieldNamesAndValues,
    removeFieldsAndValues,
    notifySubmitErrors,
    bindInput,
    model,
    errors,
    submitErrors,
    formError,
    isValid,
    isChanged,
    isReady: isValid && isChanged,
    submit: props.handleSubmit,
    addFieldNamesAndValues,
    deleteFieldNamesAndValues,
    mergeCurrentValues,
    id: opts.id
  };
}

function createFields(props) {
  const fieldNames = props.fieldNames || props.formFieldNames;
  let fields = {};
  for (const name of fieldNames) {
    fields[name] = props.bindInput(name);
  }
  return fields;
}

const enhance = (fieldNames, opts) =>
  compose(
    withState("formFieldNames", "setFieldNames", props => fieldNames || []),
    withHandlers({
      // This is what we are adding to props.
      bindInput,
      removeFieldsAndValues,
      setFieldNamesAndValues,
      addFieldNamesAndValues,
      deleteFieldNamesAndValues
    }),
    mapProps(props => ({
      ...props,
      form: createForm(
        props,
        opts
      ),
      fields: createFields(props)
    }))
  );

export default (fieldNames, opts) => WrappedComponent =>
  enhance(fieldNames, opts)(WrappedComponent);
