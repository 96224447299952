import PropTypes from "prop-types";
import { Component, createRef } from "react";
import c from "classnames";
import { label, input, div } from "tags";
import toFieldProps from "./toFieldProps";
import "./flat-checkbox.scss";

export default class FlatCheckbox extends Component {
  render() {
    const { field, style, disabled, children } = this.props;
    const inputRef = createRef();
    const checkbox = input({
      ref: inputRef,
      type: "checkbox",
      ...toFieldProps(field),
      disabled
    });

    const keyDownHandler = (ev) => {
      if (ev.key === " " || ev.key === "Enter") {
        inputRef.current?.click()
        ev.preventDefault()
      }
    }

    const container = label(
      ".flat-checkbox",
      {
        style,
        className: c(disabled && "is-disabled"),
        onKeyDown: keyDownHandler,
        tabIndex: "0"
      },
      [
        checkbox,
        div(".flat-checkbox-label", children)
      ]
    );

    return container;
  }
}

FlatCheckbox.propTypes = {
  field: PropTypes.object.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool
};
