import PropTypes from "prop-types";
import { div, label as labelTag } from "tags";
import c from "classnames";
import h from "h";
import Tooltip from "cp/tooltip/Tooltip";

function LabelledField({ width, label, help, optional, tooltip, children, name }) {
  if (label) {
    const className = c(
      "field",
      width ? `grid${width}` : "grid12",
      help && "field-with-help"
    );
    let caption;
    if (optional) {
      caption = h(".caption", [`${label} `, h("span.opt-field", "(Optional)")]);
    } else if (tooltip) {
      const tipIcon = h("span", [`${label} `, h(Tooltip, { id: name, text: tooltip  }, h("i.fas.fa-info-circle"))]);
      caption = h(".caption", h("span", tipIcon));
    } else {
      caption = h(".caption", label);
    }

    if (help) {
      return div({ className }, [labelTag([caption, children]), help]);
    } else {
      return labelTag({ className }, [caption, children]);
    }
  } else {
    return children;
  }
}

LabelledField.propTypes = {
  width: PropTypes.number,
  label: PropTypes.string,
  help: PropTypes.node,
  name: PropTypes.string
};

export default LabelledField;
