import React, { useState } from "react";
import PropTypes from "prop-types";
import c from "classnames";
import { fieldType } from "./PropTypes";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";
import Input from "./Input";

function MailAccountField(props) {
  const { width, label, help, field, domain } = props;
  const [hasFocus, setHasFocus] = useState(false);

  // Need this to keep the same border for the p tag with the domain name
  const customHandlers = {
    onBlur: (_) => setHasFocus(false),
    onFocus: (_) => setHasFocus(true),
  };

  return (
    <LabelledField name={field.name} width={width} label={label}>
      <ErrorContainer field={field} className="mail-account-field" noCheck={true}>
        <div className="mail-account-field__holder">
          <div className={c("mail-account-field__input", { ready: field.isReady })}>
            <Input
              type="text"
              field={field}
              className="form-input"
              customHandlers={customHandlers}
            />
          </div>
          <p className={c({ focus: hasFocus, error: !!field.error })}>@{domain}</p>
        </div>
      </ErrorContainer>
      {!field.error && (
        <p className="mail-account-field__helper-text">
          Up to 64 characters. You can use A-Z, 0-9, hyphen (-), underscore (_), and dot(.)
        </p>
      )}
    </LabelledField>
  );
}

MailAccountField.propTypes = {
  width: PropTypes.number,
  label: PropTypes.string.isRequired,
  field: fieldType,
  domain: PropTypes.string.isRequired,
};

export default MailAccountField;
