import React from "react";
import PropTypes from "prop-types";
import Tooltip from "react-tooltip";
import c from "classnames";
import withMetrics from "../withMetrics";
import styles from "./tooltip.module.sass";

export function TableTipIcon({ children, className, id, text }) {
  const tip = withMetrics(({metrics}) => (
    <TooltipWrapper id={id} metrics={metrics} text={text}>
      {children}
    </TooltipWrapper>
  ));
  return (
    <span
      className={c("tooltip", className)}
      aria-label={text}
    >
      {React.createElement(tip)}
    </span>
  );
}

function buildTipProps(isMobile, tipProps) {
  return isMobile
    ? Object.assign({}, tipProps, { globalEventOff: "click touch" })
    : tipProps;
}

function buildCloneProps(isMobile, tipId) {
  const base = { "data-for": tipId, "data-tip": true };
  if (isMobile) {
    return Object.assign({}, base, { "data-event": "click touch" });
  } else {
    return base;
  }
}

function TooltipWrapper(props) {
  const { children, className, id, metrics, text, ...origTipProps } = props;
  const tipId = id + "-tip";
  const isMobile = metrics.window.width < 481;
  const tipProps = buildTipProps(isMobile, origTipProps);
  const cloneProps = buildCloneProps(isMobile, tipId);
  return (
    <React.Fragment>
      <Tooltip
        className={styles.tooltip}
        place={"top"}
        effect={"solid"}
        id={tipId}
        backgroundColor="#000000"
        {...tipProps}
      >
        {text}
      </Tooltip>
      {children ? React.cloneElement(children, cloneProps) : null}
    </React.Fragment>
  );
}

TooltipWrapper.propTypes = {
  afterShow: PropTypes.func,
  id: PropTypes.string.isRequired,
  metrics: PropTypes.object.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default withMetrics(TooltipWrapper);
