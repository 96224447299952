import PropTypes from "prop-types";

export const fieldType = PropTypes.shape({
  name: PropTypes.string.isRequired
  // value: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.bool,
  //   PropTypes.number
  // ]).isRequired
});
