import PropTypes from "prop-types";
import { Component } from "react";
import h from "h";
import { fieldType } from "./PropTypes";
import toFieldProps from "./toFieldProps";
import Select from "./Select";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";

export default class SelectField extends Component {
  static propTypes = {
    width: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    field: fieldType.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string.isRequired
      })
    ).isRequired,
    defaultValue: PropTypes.string,
    noCheck: PropTypes.bool,
    readOnly: PropTypes.bool,
    customHandlers: PropTypes.shape({
      onChange: PropTypes.func
    })
  };

  lookupOptionText(options, value) {
    for (const opt of options) {
      if (opt.value === value) {
        return opt.label;
      }
    }
    // Default to first item, like a real select menu
    return options[0].label;
  }

  element(props) {
    const {
      field,
      defaultValue,
      options,
      readOnly = false,
      ...elementProps
    } = props;

    // Read-only selects look much better as a greyed out text field.
    if (readOnly) {
      const value = this.lookupOptionText(options, field.value);
      return h("input.form-input", {
        type: "text",
        value: value,
        ...elementProps,
        readOnly: true
      });
    } else {
      const singleVal = options.length === 1 ? { value: options[0].value } : {};
      return h(Select, {
        options,
        field: { ...toFieldProps(field), ...singleVal },
        className: "form-selectbox",
        defaultValue,
        ...elementProps
      });
    }
  }

  render() {
    const { width, label, tooltip, field, noCheck = false, ...props } = this.props;
    const name = field.name;

    return h(LabelledField, { width, label, tooltip, name }, [
      h(ErrorContainer, { field, noCheck, className: "select-field" }, [
        this.element({ ...props, field })
      ])
    ]);
  }
}
