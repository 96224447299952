import PropTypes from "prop-types";
import c from "classnames";
import h from "h";
import toFieldProps from "./toFieldProps";
import { div, select, option } from "tags";

function SelectGroup({ defaultValue, optionGroups, field, className, ...props }) {
  const boxProps = {
    className: c(className, field.isActive && "focus")
  };

  const defaultOption = [option({ value: "" }, "Select one...")];
  const optionGroupElements = defaultOption.concat(
    optionGroups.map(toOptionGroupTag)
  );

  const selectProps = {
    ...toFieldProps(field),
    value: selectValue(field.value, defaultValue),
    style: {
      fontWeight: 400
    },
    ...props
  };

  return div(".selectbox", boxProps, [select(selectProps, optionGroupElements)]);
}

SelectGroup.propTypes = {
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  optionGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string
        })
      )
    })
  ).isRequired
};

function selectValue(value, defaultValue) {
  if (value == null || value == "") {
    return defaultValue;
  }

  return value;
}

function toOptionGroupTag({ label, options }) {
  return h("optgroup", { label }, options.map(toOptionTag));
}

function toOptionTag({ label, value }) {
  return option({ value, disabled: value === "---" }, label);
}

export default SelectGroup;
