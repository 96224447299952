import { Component } from "react";
import c from "classnames";
import h from "h";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";
import Input from "./Input";

export default class ToggleField extends Component {
  render() {
    const {
      width,
      label,
      help,
      field,
      autoComplete,
      autoFocus,
      tooltip,
      noCheck = false
    } = this.props;

    const name = field.name;

    const classNames = c("toggle", { "no-check": noCheck });

    return h(LabelledField, { width, label, help, tooltip, name }, [
      h(ErrorContainer, { field, className: classNames, noCheck: true }, [
        h(".onoff_slide", { style: { marginLeft: "5px" } }, [
          h(Input, {
            type: "checkbox",
            field,
            props: { autoComplete, autoFocus, id: field.name }
          }),
          h("label", { htmlFor: field.name,  "data-testid": `${field.name}-tf` })
        ])
      ])
    ]);
  }
}
