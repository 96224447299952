import PropTypes from "prop-types";
import { Component } from "react";
import c from "classnames";
import { input } from "tags";
import toFieldProps from "./toFieldProps";

class Input extends Component {
  componentDidMount() {
    if (this.props.props && this.props.props.autoFocus) {
      this.input.focus();
    }
  }

  render() {
    const { type, field, className, props, customHandlers } = this.props;

    const cls = c(className, props && props.className);
    let eventHandlers = {};

    if (customHandlers) {
      for (let i = 0, keys = Object.keys(customHandlers); i < keys.length; i++) {
        eventHandlers[keys[i]] = e => {
          field[keys[i]](e);
          customHandlers[keys[i]](e);
        };
      }
    }

    return input({
      type,
      ...toFieldProps(field),
      ...noAuto,
      ...props,
      ...eventHandlers,
      className: cls,
      ref: e => (this.input = e)
    });
  }
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  props: PropTypes.object,
  autoFocus: PropTypes.bool,
  customHandlers: PropTypes.object
};

const noAuto = {
  autoComplete: "off",
  autoCorrect: "off",
  autoCapitalize: "off",
  spellCheck: "false"
};

export default Input;
