import PropTypes from "prop-types";
import { Component } from "react";
import { label, input, div } from "tags";
import "./checkbox.css";
import toFieldProps from "./toFieldProps";

export default class NativeCheckbox extends Component {
  render() {
    const { field, style, disabled, children, className } = this.props;

    return label(".checkbox", { style, className }, [
      input(".checkbox-input", {
        type: "checkbox",
        ...toFieldProps(field),
        disabled
      }),
      div(".checkbox-label", children)
    ]);
  }
}

NativeCheckbox.propTypes = {
  field: PropTypes.object.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool
};
