import PropTypes from "prop-types";
import { Component } from "react";
import h from "h";
import { fieldType } from "./PropTypes";
import SelectGroup from "./SelectGroup";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";

export default class SelectGroupField extends Component {
  static propTypes = {
    width: PropTypes.number,
    label: PropTypes.string,
    field: fieldType.isRequired,
    optionGroups: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string
          })
        )
      })
    ).isRequired
  };

  render() {
    const { defaultValue, width, label, field, optionGroups, ...props } = this.props;

    return h(LabelledField, { width, label }, [
      h(ErrorContainer, { field, className: "select-field" }, [
        h(SelectGroup, {
          defaultValue,
          optionGroups,
          field,
          className: "form-selectbox",
          ...props
        })
      ])
    ]);
  }
}
