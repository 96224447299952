import PropTypes from "prop-types";
import { Component } from "react";
import c from "classnames";
import { button } from "tags";

class SubmitButton extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired
  };

  render() {
    const { text } = this.props;
    const form = this.props.form;
    const isValid = form.isReady;

    const className = c("btn", "submit", isValid && "btn-primary");

    return button({ className, form: form.id, type: "submit" }, text);
  }
}

export default SubmitButton;
