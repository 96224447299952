import PropTypes from "prop-types";
import { Component } from "react";

import h from "h";
import { fieldType } from "./PropTypes";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";
import Input from "./Input";

class TextField extends Component {
  static propTypes = {
    width: PropTypes.number,
    label: PropTypes.string,
    field: fieldType,
    help: PropTypes.node,
    tooltip: PropTypes.string,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    customHanders: PropTypes.object
  };

  render() {
    const {
      width,
      label,
      help,
      field,
      tooltip,
      autoComplete,
      autoFocus,
      readOnly = false,
      optional = false,
      children,
      customHandlers
    } = this.props;

    const name = field.name;

    return h(LabelledField, { name, width, label, tooltip, help, optional }, [
      h(ErrorContainer, { field }, [
        h(Input, {
          type: "text",
          field,
          className: "form-input",
          props: { autoComplete, autoFocus, readOnly },
          customHandlers
        }),
        children
      ])
    ]);
  }
}

export default TextField;
