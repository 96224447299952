import PropTypes from "prop-types";
import { Component } from "react";
import { div } from "tags";
import { fasIcon } from "h";

export default class FormError extends Component {
  static propTypes = {
    text: PropTypes.string
  };

  render() {
    const { text, customizer } = this.props;
    const errorMessage = customize(text, customizer);
    if (errorMessage) {
      return div(".form-error", [
        div(".error-icon", [
          fasIcon("exclamation-circle", { className: "text-danger icon" })
        ]),
        div(".error-text", errorMessage)
      ]);
    } else {
      return null;
    }
  }
}

function customize(text, customizer) {
  if (!customizer || !text) {
    return text;
  }

  const replacement = customizer(text);
  return replacement || text;
}
