import { compose, withState, withHandlers } from "recompose";

const setProperty = props => (name, value) =>
  props.setModel({ ...props.model, [name]: value });

const mergeCurrentValues = props => newModel => {
  return props.setModel({ ...props.model, ...newModel });
};

const setCurrentValues = props => newModel => {
  return props.setModel({ ...newModel });
};

const removeFields = props => fields => {
  let nextModel = { ...props.model };
  for (const name of fields) {
    delete nextModel[name];
  }
  return props.setModel(nextModel);
};

const setFieldNamesAndValues = props => fields => {
  const nextFormInitialValues = { ...props.formInitialValues, ...fields };
  const nextModel = { ...props.model, ...fields };
  return props.setFormInitialValues(nextFormInitialValues, () =>
    props.setModel(nextModel)
  );
};

const enhance = compose(
  withState(
    "formInitialValues",
    "setFormInitialValues",
    props => props.initialValues || {}
  ),
  withState("model", "setModel", props => props.formInitialValues),
  withHandlers({
    setProperty,
    setCurrentValues,
    mergeCurrentValues,
    removeFields,
    setFieldNamesAndValues
  })
);

export default () => WrappedComponent => enhance(WrappedComponent);
