import c from "classnames";
import PropTypes from "prop-types";
import h from "h";
import { label as labelTag, option } from "tags";

const FieldlessSelect = ({
  label = "",
  onChange,
  options,
  width,
  value,
  readOnly,
  defaultOption,
  testId
}) => {
  const className = c("field", width ? `grid${width}` : "grid12");
  const caption = h(".caption", label);
  if (readOnly) {
    const value = lookupOptionText(options, value);
    return h("input.form-input", {
      type: "text",
      value: value,
      readOnly: true
    });
  } else {
    const optionTags = addDefaultOption(options, defaultOption).map(toOptionTag);
    const select = h("select", { onChange, value, "data-testid": testId}, optionTags);
    const holder = h(".selectbox.form-selectbox", select);
    return labelTag({ className }, [caption, holder]);
  }
};

function lookupOptionText(options, value) {
  for (const opt of options) {
    if (opt.value === value) {
      return opt.label;
    }
  }
  return options[0].value;
}

function addDefaultOption(options, defaultOption) {
  if (defaultOption) {
    const label = defaultOption.label || "Select one...";
    const value = defaultOption.value || "";
    return [{ label, value }, ...options];
  }
  return options;
}

function toOptionTag({ label, value }) {
  return option({ value }, label);
}

FieldlessSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  width: PropTypes.number,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  defaultOption: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  testId: PropTypes.string
};

export default FieldlessSelect;
