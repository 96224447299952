import PropTypes from "prop-types";
import { Component } from "react";

import h from "h";
import { fieldType } from "./PropTypes";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";
import NativeCheckbox from "./NativeCheckbox";

class NativeCheckboxField extends Component {
  static propTypes = {
    width: PropTypes.number,
    label: PropTypes.node.isRequired,
    field: fieldType,
    style: PropTypes.object
  };

  render() {
    const { width, label, field, disabled = false, style } = this.props;

    return h(LabelledField, { width, style }, [
      h(ErrorContainer, { field, className: "checkbox-field" }, [
        h(
          NativeCheckbox,
          {
            type: "checkbox",
            field,
            disabled,
            style
          },
          label
        )
      ])
    ]);
  }
}

export default NativeCheckboxField;
