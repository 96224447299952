import { compose, withState, withHandlers, withProps } from "recompose";
import set from "lodash/fp/set";
import unset from "lodash/fp/unset";

const isDirty = props => name => !!props.dirtyFields[name];
const isChanged = props => name => {
  const oldValue = (props.formInitialValues || {})[name] || "";
  const newValue = props.model[name] || "";
  return oldValue !== newValue;
};

const enterField = props => name =>
  props.setCurrentFocus({ name, value: props.model[name] });
const exitField = props => name => {
  props.setCurrentFocus(null, () => {
    if (!props.dirtyFields[name] && valueDidChange(name, props)) {
      props.setDirtyFields(set(name, true, props.dirtyFields));
    }
  });
};

function valueDidChange(name, props) {
  if (!props.currentFocus) return;
  const oldValue = props.currentFocus.value || "";
  const newValue = props.model[name] || "";
  return oldValue != newValue;
}

const enhance = compose(
  withState("dirtyFields", "setDirtyFields", {}),
  withState("currentFocus", "setCurrentFocus", null),
  withHandlers({
    isDirty,
    isChanged,
    enterField,
    exitField
  })
);

export default () => WrappedComponent => enhance(WrappedComponent);
