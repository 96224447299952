import PropTypes from "prop-types";
import { Component } from "react";

import h from "h";
import { fieldType } from "./PropTypes";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";
import Input from "./Input";
import CreditCardIcons from "./creditCardIcons";

export default class CreditCardField extends Component {
  static propTypes = {
    width: PropTypes.number,
    label: PropTypes.string,
    field: fieldType,
    card: PropTypes.object.isRequired,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool
  };

  render() {
    const { width, label, field, card, autoComplete, autoFocus } = this.props;

    return h(LabelledField, { width, label }, [
      h(ErrorContainer, { field, showReady: false }, [
        h(CreditCardIcons, { card }, [
          h(Input, {
            type: "text",
            field,
            className: "form-input",
            props: { autoComplete, autoFocus }
          })
        ])
      ])
    ]);
  }
}
