import PropTypes from "prop-types";
import { Component } from "react";
import h from "h";
import { fieldType } from "./PropTypes";
import SelectField from "./SelectField";
import TextField from "./TextField";

export default class ComboField extends Component {
  static propTypes = {
    field: fieldType.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    )
  };

  render() {
    const { field, options, ...props } = this.props;
    if (options) {
      return h(SelectField, { field, options, ...props });
    } else {
      return h(TextField, { field, ...props });
    }
  }
}
