import PropTypes from "prop-types";
import c from "classnames";
import { button } from "tags";

function BackButton({ onClick, children }) {
  const className = c("btn-link", "btn-caps");
  return button({ className, type: "button", onClick }, children);
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default BackButton;
