import PropTypes from "prop-types";
import { Component } from "react";

import h from "h";
import { fieldType } from "./PropTypes";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";
import Input from "./Input";

export default class PhoneField extends Component {
  static propTypes = {
    width: PropTypes.number,
    label: PropTypes.string,
    field: fieldType,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool
  };

  render() {
    const { width, label, field, autoComplete, autoFocus, readOnly } = this.props;

    return h(LabelledField, { width, label }, [
      h(ErrorContainer, { field, className: "phone-field" }, [
        h(Input, {
          type: "tel",
          field,
          className: "form-input",
          props: { autoComplete, autoFocus, readOnly }
        })
      ])
    ]);
  }
}
