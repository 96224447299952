import PropTypes from "prop-types";
import { Component } from "react";

import h from "h";
import LabelledField from "./LabelledField";

class StaticField extends Component {
  static propTypes = {
    width: PropTypes.number,
    label: PropTypes.string,
    children: PropTypes.node,
    help: PropTypes.node
  };

  render() {
    const { width, label, children, help } = this.props;
    return h(LabelledField, { width, label, help }, children);
  }
}

export default StaticField;
