import PropTypes from "prop-types";
import c from "classnames";
import { div } from "tags";

function CreditCardIcons({ card, children }) {
  const { type, isValid, isPotentiallyValid } = card;
  const className = c(
    type && `cc-type-${card.type}`,
    isValid && "cc-valid",
    !isPotentiallyValid && "cc-invalid"
  );

  return div(".ccicons", { className }, children);
}

CreditCardIcons.propTypes = {
  card: PropTypes.shape({
    isValid: PropTypes.bool.isRequired,
    isPotentiallyValid: PropTypes.bool.isRequired,
    type: PropTypes.string
  })
};

export default CreditCardIcons;
