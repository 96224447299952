import PropTypes from "prop-types";
import c from "classnames";
import { div, select, option } from "tags";
import toFieldProps from "./toFieldProps";

function Select({
  options,
  field,
  placeholder,
  defaultValue = "",
  className,
  customHandlers = {},
  ...props
}) {
  const boxProps = {
    className: c(className, field.isActive && "focus")
  };

  const optionElements = addDefaultOption(options, field, placeholder)
    .map(dashesToUnicode)
    .map(toOptionTag);

  const selectProps = {
    ...toFieldProps(field),
    value: field.value || defaultValue,
    style: {
      fontWeight: 400
    },
    ...props,
    ...customHandlers
  };

  return div(".selectbox", boxProps, [select(selectProps, optionElements)]);
}

Select.propTypes = {
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string
    }).isRequired
  ).isRequired,
  /*
    Override default form event handlers for select component.
    Acceptable keys: onChange onBlur onFocus
    Each key should contain a function with the signature Event -> Any
  */
  customHandlers: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  })
};

function addDefaultOption(options, field, placeholder) {
  const label = placeholder || "Select one...";
  const value = field.value ? String(field.value) : "";
  const vals = options.map(opt => opt.value);

  if (vals.indexOf(field.value) === -1) {
    return [{ label, value }, ...options];
  } else {
    return options;
  }
}

function dashesToUnicode(option) {
  // used in CIRA legal types dropdown
  if (option.value === "---") {
    return { label: "─".repeat(20), value: "---" };
  } else {
    return option;
  }
}

function toOptionTag({ label, value }) {
  return option({ value, disabled: value === "---" }, label);
}

export default Select;
