import formValues from "./formValues";
import formSubmit from "./formSubmit";
import formValidation from "./formValidation";
import formDirty from "./formDirty";
import formFields from "./formFields";
import { compose } from "recompose";

const form = (fields, validate, opts = {}) =>
  compose(
    formValues(),
    formSubmit(),
    formValidation(validate),
    formDirty(),
    formFields(fields, opts)
  );

export default form;
