import { compose, withState, withHandlers } from "recompose";

const handleSubmit = props => callback => {
  if (callback) {
    const values = { ...props.model };
    values.asMutable = () => values;
    return callback(values);
  }
};

const enhance = compose(
  withState("submitErrors", "notifySubmitErrors", null),
  withHandlers({ handleSubmit })
);

export default () => WrappedComponent => enhance(WrappedComponent);
