import PropTypes from "prop-types";
import { Component } from "react";

import h from "h";
import { fieldType } from "./PropTypes";
import getEmailSuggestion from "./emailSuggestion";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";
import Input from "./Input";
import { div, button } from "tags";
import toFieldProps from "./toFieldProps";

class EmailField extends Component {
  static propTypes = {
    width: PropTypes.number,
    label: PropTypes.string,
    field: fieldType,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool
  };

  UNSAFE_componentWillMount() {
    this.timeouts = {};
  }

  componentWillUnmount() {
    this.cancelTimeouts();
  }

  setTimeout(f, ms) {
    const timeoutId = setTimeout(f, ms);
    this.timeouts[timeoutId] = timeoutId;
  }

  cancelTimeouts() {
    for (const id in this.timeouts) {
      clearTimeout(id);
    }
    this.timeouts = {};
  }

  hint(text, field) {
    const style = {
      fontSize: 12,
      marginTop: 4,
      textTransform: "none"
    };
    // const onClick = () => field.onChange(text);
    function onClick() {
      field.onChange(text);
    }

    return div(".suggestion", { style }, [
      "Do you mean ",
      button(".btn-link", { onClick, style, tabIndex: -1 }, text),
      "?"
    ]);
  }

  render() {
    const { width, label, field, autoComplete, autoFocus } = this.props;
    const hint = getEmailSuggestion(field.value);

    const delayBlur = field => ({
      ...toFieldProps(field),
      onBlur: e => {
        e.persist();
        this.setTimeout(() => field.onBlur(e), 150);
      }
    });

    return h(LabelledField, { width, label }, [
      h("div", [
        h(ErrorContainer, { field }, [
          h(Input, {
            type: "text",
            field: delayBlur(field),
            className: "form-input",
            props: { autoComplete, autoFocus }
          })
        ]),
        hint && this.hint(hint, field)
      ])
    ]);
  }
}

export default EmailField;
