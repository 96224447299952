import PropTypes from "prop-types";
import c from "classnames";
import { h, fasIcon } from "h";

const hasLowerCase = pw => /[a-z]/.test(pw);
const hasUpperCase = pw => /[A-Z]/.test(pw);
const hasMixedCase = pw => hasLowerCase(pw) && hasUpperCase(pw);
const hasNumber = pw => /[0-9]/.test(pw);
const hasSpecialChar = pw => /[!@#$%^&*()_\-+={}\[\]|\/~`?<>:;\\.,']/.test(pw);
const minLength = 14;
const isMinLength = pw => pw.length >= minLength;

export const validators = {
  hasLowerCase,
  hasUpperCase,
  hasMixedCase,
  hasNumber,
  hasSpecialChar,
  isMinLength
};

const requirements = [
  {
    message: "minimum " + minLength +" characters",
    validator: isMinLength,
    type: "length"
  },
  {
    message: "must include mixed case",
    validator: hasMixedCase,
    type: "case"
  },
  {
    message: "must include a number",
    validator: hasNumber,
    type: "number"
  },
  {
    message: "must include a special character",
    validator: hasSpecialChar,
    type: "character"
  }
];

const requirementItems = pw =>
  requirements.map(req =>
    h(RequirementItem, {
      message: req.message,
      isValid: req.validator(pw),
      type: req.type
    })
  );

const reqIcon = isValid =>
  isValid ? fasIcon("check-circle") : fasIcon("times-circle");

const RequirementItem = ({ message, isValid, type }) => {
  const className = c(
    "pw-reqs__item",
    `pw-reqs__item-${isValid ? "valid" : "invalid"}`
  );
  return h(
    "div",
    {
      className,
      id: `req-${type}`
    },
    [reqIcon(isValid), h("span", message)]
  );
};

RequirementItem.propTypes = {
  message: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
}

export const StandAlonePasswordRequirements = ({ password }) =>
  h(".pw-reqs-modal", [
    h("p.pw-reqs__message", "Your password must meet the following requirements:"),
    h(".pw-reqs__items-modal", [
      h("div", [
        h(RequirementItem, {
          message: requirements[0].message,
          isValid: requirements[0].validator(password),
          type: requirements[0].type
        }),
        h(RequirementItem, {
          message: requirements[1].message,
          isValid: requirements[1].validator(password),
          type: requirements[1].type
        })
      ]),
      h("div", [
        h(RequirementItem, {
          message: requirements[2].message,
          isValid: requirements[2].validator(password),
          type: requirements[2].type
        }),
        h(RequirementItem, {
          message: requirements[3].message,
          isValid: requirements[3].validator(password),
          type: requirements[3].type
        })
      ])
    ])
  ]);

StandAlonePasswordRequirements.propTypes = {
  password: PropTypes.string
};

export const PasswordRequirementsToolTip = ({ password }) =>
  h(".pw-reqs-tooltip", [
    h("p.pw-reqs__message", "Your password must meet the following requirements:"),
    h(".pw-reqs__items", requirementItems(password))
  ]);

PasswordRequirementsToolTip.propTypes = {
  password: PropTypes.string
};
