import PropTypes from "prop-types";
import { Component } from "react";

import h from "h";
import { div } from "tags";
import { fieldType } from "./PropTypes";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";
import Input from "./Input";
import FlatCheckbox from "./FlatCheckbox";

export default class PasswordField extends Component {
  static propTypes = {
    width: PropTypes.number,
    label: PropTypes.string,
    help: PropTypes.node,
    field: fieldType,
    show: fieldType,
    autoFocus: PropTypes.bool
  };

  render() {
    const {
      width,
      label,
      help,
      field,
      show,
      optional = false,
      autoFocus
    } = this.props;
    const type = show.checked ? "text" : "password";

    return h(LabelledField, { width, label, help, optional }, [
      h(ErrorContainer, { field, className: "password-field" }, [
        h(Input, {
          type,
          field,
          className: "passwd-input",
          autoFocus
        }),
        div(".passwd-show", [h(FlatCheckbox, { field: show }, "show")])
      ])
    ]);
  }
}
