import PropTypes from "prop-types";
import { Component } from "react";

import h from "h";
import { fieldType } from "./PropTypes";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";
import FlatCheckbox from "./FlatCheckbox";

export default class CheckboxField extends Component {
  static propTypes = {
    width: PropTypes.number,
    label: PropTypes.node.isRequired,
    field: fieldType
  };

  render() {
    const { width, label, field, disabled = false } = this.props;

    return h(LabelledField, { width }, [
      h(ErrorContainer, { field, className: "checkbox-field field" }, [
        h(
          FlatCheckbox,
          {
            type: "checkbox",
            field,
            disabled
          },
          label
        )
      ])
    ]);
  }
}
